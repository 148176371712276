import React, { Component } from "react";
import '../css/AdminPanel.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class AdminAddExtract extends Component {


    constructor(props) {
        super(props);
        this.state = {
        }

        this.changeExtract = this.changeExtract.bind(this);
    }

    componentDidMount() {
        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl });
    }


    obfuscateExtract(extract, answer) {

        let words = answer.split(' ');
        // Blank is the same length as the title
        // let blank = '';
        let blank = '<blank>';
        let prev = false;
        for (let i = 0; i < answer.length; i++) {
            if (answer[i] === ' ') { blank += '</blank> '; prev = true } // Pour les mots composés
            else {
                if (prev) blank += '<blank>'
                if (answer[i] === '-') blank += '</blank>-<blank>';
                else blank += '_';
                prev = false;
            }
        }

        blank += '</blank>';

        // Replace the page title by the blank
        const extractRegex = new RegExp(answer, 'gi');
        let extractDisplay = extract.replace(extractRegex, blank);

        if (words.length > 1) {
            for (let i = 0; i < words.length; i++) {
                if (
                    words[i].toLowerCase() !== 'de'
                    && words[i].toLowerCase() !== 'du'
                    && words[i].toLowerCase() !== 'des'
                    && words[i].toLowerCase() !== 'au'
                    && words[i].toLowerCase() !== 'aux'
                    && words[i].toLowerCase() !== 'en'
                    && words[i].toLowerCase() !== 'à'
                    && words[i].toLowerCase() !== 'la'
                    && words[i].toLowerCase() !== 'le'
                    && words[i].toLowerCase() !== 'in'
                    && words[i].toLowerCase() !== 'ex'
                    && words[i].toLowerCase() !== 'et'
                    && words[i].toLowerCase() !== 'the'
                    && words[i].toLowerCase() !== 'of'
                    && words[i].toLowerCase() !== 'and'
                ) {
                    // let blank2 = '';
                    let blank2 = '<blank>';
                    for (let j = 0; j < words[i].length; j++) {
                        blank2 += '_';
                    }
                    blank2 += '</blank>';

                    const extractRegex = new RegExp(words[i].toLowerCase(), 'gi');
                    extractDisplay = extractDisplay.replace(extractRegex, blank2);
                }
            }
        }
        return extractDisplay;
    }

    changeExtract() {
        this.setState({
            extract: document.getElementById('extract').innerHTML,
        });
    }


    getWikiData(values) {
        let query = values.search;

        if (query === '') return;

        let language = values.language;

        query = query.replace(' ', '_');

        this.setState({
            status: 'fetching',
            query: query,
            blockSubmit: true,
            exist: false,
            extractLanguage: language
        });

        if (language === '') return;

        const sentences = 6;
        let url = 'https://' + language + '.wikipedia.org/w/api.php?action=query&format=json&origin=*&prop=extracts&titles=' + query + '&exsentences=' + sentences + '&exintro=1';

        fetch(url)
            .then(res => res.json().then(json => {


                let pageId = Object.keys(json.query.pages)[0];
                let extract = json.query.pages[pageId].extract;

                console.log(extract)
                console.log(json)
                if (extract && extract !== '<!--' && !extract.match(/may refer to:/) && !extract.match(/can refer to:/)) {
                    // If we hit a page, display the extract
                    this.setState({ searchQuery: [], blockSubmit: false })
                    this.parseWikiData(json);
                } else {
                    // If no page is found, search wikipedia
                    this.setState({ status: 'notfound' });

                    let url = 'https://' + this.state.extractLanguage + '.wikipedia.org/w/api.php?action=query&list=search&origin=*&srsearch=' + this.state.query + '&format=json';

                    fetch(url)
                        .then(res => res.json().then(json => {
                            let results = json.query.search;
                            this.setState({ searchQuery: results })
                        }));
                }
            }));
    }

    parseWikiData(data) {

        let pageId = Object.keys(data.query.pages)[0];
        let extract = data.query.pages[pageId].extract;
        let pageTitle = data.query.pages[pageId].title;

        if (extract) {

            // Remove parenthesis from the title
            let answer = pageTitle.replace(/\((.+)\)/g, "");
            answer = answer.trim();
            extract = extract.trim();


            // Remove HTML tags
            extract = extract.replace(/<\/?[^>]+(>|$)/g, "").replace(/&#160;/g, ' ').trim()


            this.setState({
                pageId: pageId,
                extract: extract,
                answer: answer,
                pageTitle: pageTitle,
                status: 'reviewing'
            });

            this.checkIfAlreadyPageExists()
        } else {
            console.log('Page does not exist')
            // this.setState({ status: 'error', blockSubmit: 'true' })
        }
    }


    checkIfAlreadyPageExists() {
        const params = new URLSearchParams({ pageId: this.state.pageId, language: this.state.extractLanguage })
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'extract/get?' + params, options)
            .then(res => res.json().then(json => {
                if (json.found) {
                    this.setState({ blockSubmit: true, exist: true })
                } else {
                    this.setState({ blockSubmit: false, exist: false })
                }
            }))
            .catch(err => console.log(err));
    }

    registerExtract() {

        this.setState({ status: 'submitting' })
        const data = {
            pageId: this.state.pageId,
            extract: this.state.extract,
            language: this.state.extractLanguage,
            answer: this.state.answer,
            pageTitle: this.state.pageTitle,
            user: this.props.user,
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
        fetch(this.state.serverUrl + 'extract/new', options)
            .then(res => res.json().then(json => {
                console.log(json);
                if (json.message === 'success') {
                    this.setState({
                        pageId: '',
                        extract: '',
                        answer: '',
                        pageTitle: '',
                        query: '',
                        exist: false,
                        status: 'success'
                    });

                    document.getElementById('search').value = '';

                }
                else if (json.message === 'authentication') {
                    this.setState({ status: 'authentication error' })
                }
                this.checkIfAlreadyPageExists()
            }));
    }

    suggestedSearch(title) {
        document.getElementById('search').value = title;

        this.getWikiData({ language: this.state.extractLanguage, search: title })

    }

    render() {
        let suggestions = [];
        if (this.state.searchQuery && this.state.searchQuery.length > 0) {
            suggestions = this.state.searchQuery;
        }


        let extractContainer = [];
        let warningStyle = { color: 'black' }
        let extractDisplay = '';
        if (this.state.answer && this.state.extract) {

            extractDisplay = this.obfuscateExtract(this.state.extract, this.state.answer)

            if (extractDisplay) {
                if (extractDisplay.length >= 800) warningStyle = { color: 'orange' }
                if (extractDisplay.length >= 1000) warningStyle = { color: 'red' }


                let extract = extractDisplay.replace(/(\r|\n)/g, ' <br> '); //.replace(/-/g, ' - ');
                extract = extract.split(' ');//every space not preceded by <span


                for (let i = 0; i < extract.length; i++) {
                    let word = extract[i];
                    if (word.match(/<blank>/)) {
                        let splitWord = word.split(/<blank>([a-zA-Z_']+)<\/blank>/g);

                        word = <span>{splitWord.map(item => (
                            item.substr(0, 1) === '_' ? <span class="obfuscatedWord">{item}</span> : item
                        ))
                        }</span>
                    } else {
                        word = word.replace(/<br>/g, '\n');
                    }

                    extractContainer.push(<span id={'word_' + i}>{word} </span>)
                }
            }
        }
        return (
            <div id="AdminAddExtract">
                <button
                    className="backButton"
                    onClick={this.props.backToMain}
                >←</button>
                <Formik
                    initialValues={{ search: '', language: '' }}

                    validationSchema={
                        Yup.object({
                            search: Yup.string(),
                            language: Yup.string()
                                .required()
                        })
                    }

                    onSubmit={async (values) => {
                        this.getWikiData(values);
                    }}
                >
                    <Form className="getWikiDataForm">
                        {this.state.status}<br /><br />
                        <Field as="select" name="language" className="languageSelect">
                            <option value="">{this.props.strings['admin_selectLanguage']}</option>
                            {this.props.languages.includes("en") ?
                                <option value="en">English</option>
                                : null}
                            {this.props.languages.includes("eo") ?
                                <option value="eo">Esperanto</option>
                                : null}
                            {this.props.languages.includes("fr") ?
                                <option value="fr" default>Français</option>
                                : null}
                        </Field>

                        <Field autoFocus id="search" autofocus={true} name="search" placeholder={this.props.strings['admin_searchPlaceholder']} className="inputSearch" />

                        <button
                            type="submit"
                            className="submitMessage"
                        >→</button><br />
                        <span className="formError">
                            <ErrorMessage name="search" />
                            <ErrorMessage name="language" />

                        </span>
                    </Form>
                </Formik>


                {/* If word not found, suggest others */}
                {suggestions.length > 0 ?
                    <div className="didyoumeanContainer">{this.props.strings['admin_didyoumean']}<br />
                        {suggestions.map(item =>
                            <div className="didyoumean" onClick={() => this.suggestedSearch(item.title)}>{item.title}</div>
                        )}
                    </div>
                    : null}



                <div id="addToDatabaseForm">
                    {extractDisplay.length > 0 ?
                        <span style={warningStyle} className="adminWordLimit">
                            {extractDisplay.length} {extractDisplay.length > 1000 ? this.props.strings['admin_tooLong'] : null}<br />
                            {this.state.exist ? this.props.strings['admin_already'] : null}
                        </span>
                        : null}

                    <h2 style={{ color: '#fafafa' }}>{this.state.answer}</h2>
                    <p id="Extract" className="adminExtract" style={{ fontSize: '1.2em' }}>{extractContainer}</p>
                    <br /><br />
                    <Formik
                        initialValues={{
                            extract: this.state.extract,
                        }}
                        onSubmit={async (values) => {
                            this.registerExtract(values);
                        }}
                        enableReinitialize='true'
                    >
                        <Form className="changeExtract">
                            <Field id="extract" name="extract" as='textarea' className="addEntryExtract" onBlur={this.changeExtract} />
                            <button className='addEntrySubmit' type='submit' disabled={this.state.blockSubmit} >{this.props.strings['admin_add']}</button>
                        </Form>


                    </Formik>
                </div>
            </div>
        );
    }
}


export default AdminAddExtract;
